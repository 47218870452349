<template>
  <Frame title="Configuración subcontratación">
    <Layout>
      <Column size="3" />
      <Column size="2">
        <ActionCard
          image="/assets/by-company.svg"
          text="Por compañía"
          @click.native="goTo('byCompany')"
          class="custom-action-card"
        >
        </ActionCard>
      </Column>
      <Column size="1" />
      <Column size="2">
        <ActionCard
          image="/assets/by-employee.svg"
          text="Por colaborador"
          @click.native="goTo('byEmployee')"
          class="custom-action-card"
        >
        </ActionCard>
      </Column>
    </Layout>
  </Frame>
</template>

<script>
export default {
  name: 'Outsourcings',
  mounted() {
    // console.log("iniciado")
  },
  methods: {
    goTo(path) {
      this.$router.push({
        name: path,
      });
    },
  },
};
</script>

<style lang="scss">
.custom-action-card {
  margin-top: 50px;
  background-color: #f2f5f8;
  padding: 24px 19px;
  cursor: pointer;
  // justify-content: center;
  // text-align: center;
  border: 1px solid #d5dbf3;
  .action-card__text {
    font-size: 16px;
    margin-top: 25px;
    font-weight: 600;
    color: #333333;
  }
}
</style>
